<template>
  <div class="step-playlist  o-panel">
    <div class="o-panel__inner">
      <div class="u-vspace-s">
        <h1 class="u-h1">Und so klingen deine Gefühle</h1>
        <p class="u-p">
          Höre jetzt, was dein Gesicht über deine Emotionen verraten hat – mit
          deiner einzigartigen Spotify-Playlist. Personalisiere deine Playlist
          noch ein Stück weiter! Nenne uns dein Lieblingsgenre oder logge dich
          mit deinem Spotify-Account ein.
        </p>
      </div>
      <div class="main">
        <div class="main__list">
          <iframe
            v-if="!isLoading && !isLegacyBrowser"
            @load="onPlaylistWidgetReady"
            :src="`https://open.spotify.com/embed/playlist/${playlistId}`"
            width="100%"
            height="330"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
          <div
            v-if="!playlistWidgetLoaded && !isLegacyBrowser"
            class="c-spinner"
          >
          </div>
          <div v-if="isLegacyBrowser && !isLoading && fallbackPlaylistLink" class="main__fallback">
            <a :href="fallbackPlaylistLink" target="_blank">Playlist in Spotify öffnen</a>
          </div>
        </div>
        <div class="main__side">
          <div class="main__customize">
            <template v-if="!isLoggedIn">
              <div class="u-vspace-s">
                <div
                  class="c-select"
                  :class="{
                    'c-select--default': !selectedGenre,
                    'c-select--disabled': isLoading
                  }"
                >
                  <span class="c-select__icon"><DownIcon /></span>
                  <select
                    v-model="selectedGenre"
                    @change="createPlaylist(selectedGenre)"
                    class="c-select__input"
                    :disabled="isLoading"
                  >
                    <option disabled value="">Dein Lieblingsgenre</option>
                    <option
                      v-for="genre in genres"
                      :value="genre.id"
                      :key="genre.id"
                      >{{ genre.name }}</option
                    >
                  </select>
                </div>
              </div>
              <button
                class="c-button c-button--pill"
                @click="attemptLogin"
                :disabled="isLoading"
              >
                Mit Spotify verbinden
              </button>
            </template>
            <template v-if="isLoggedIn && !isLoading">
              <button
                v-if="!isPlaylistFollowed"
                class="c-button c-button--pill"
                @click="followPlaylist"
                :disabled="isLoading"
              >
                Playlist speichern
              </button>
              <p v-else class="u-p">Playlist gespeichert!</p>
            </template>
          </div>
          <div class="main__actions">
            <button
              class="c-button c-button--pez"
              @click="$emit('goto-step', 0)"
            >
              Nochmal messen</button
            ><br />
            <button
              class="c-button c-button--pez c-button--hollow"
              @click="$emit('goto-next-step')"
            >
              Zum Gewinnspiel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import genres from '@/content/genres'
import spotifyService from '@/services/SpotifyService'
import DownIcon from '@/assets/images/new/genre-dropdown-icon.svg?inline'
import device from '@/util/device'
import { mapState } from 'vuex'

const API_URL = '/api/playlist'

export default {
  components: {
    DownIcon
  },
  data() {
    return {
      isLoggedIn: false,
      isCreatingPlaylist: false,
      isPlaylistFollowed: false,
      playlistWidgetLoaded: false,
      playlistId: null,
      selectedGenre: '',
      genres: genres
    }
  },
  computed: {
    ...mapState(['analysis', 'photoPath']),
    isLoading() {
      return !(this.playlistId != null && !this.isCreatingPlaylist)
    },
    isLegacyBrowser() {
      return device.isIE()
    },
    fallbackPlaylistLink() {
      return this.playlistId !== null
        ? `https://open.spotify.com/playlist/${this.playlistId}`
        : false
    }
  },
  activated() {
    this.isCreatingPlaylist = false
    this.playlistWidgetLoaded = false
    this.isPlaylistFollowed = false
    this.playlistWidgetLoaded = false
    this.playlistId = null
    this.selectedGenre = ''
    this.createPlaylist()
  },
  methods: {
    onPlaylistWidgetReady() {
      this.playlistWidgetLoaded = true
    },
    createPlaylist(genreName = null) {
      this.isCreatingPlaylist = true
      this.playlistWidgetLoaded = false
      this.playlistId = null
      let options = {}
      if (genreName) {
        options.genre = genreName
      }
      if (this.isLoggedIn) {
        options.accessToken = spotifyService.getAccessToken()
      }

      axios
        .post(API_URL, options)
        .then(response => {
          this.playlistId = response.data.playlist
          this.isCreatingPlaylist = false
        })
        .catch(error => {
          console.log('error during playlist generation', error)
          this.isCreatingPlaylist = false
          this.selectedGenre = ''
        })
    },
    attemptLogin() {
      spotifyService
        .attemptLogin()
        .then(success => {
          console.log('login complete', success)
          this.isLoggedIn = true
          this.createPlaylist()
        })
        .catch(error => {
          console.log('login error', error)
          this.isLoggedIn = false
        })
    },
    followPlaylist() {
      spotifyService.followPlaylist(this.playlistId).then(success => {
        if (success) {
          this.isPlaylistFollowed = true
          console.log('playlist followed!')
        } else {
          console.log('playlist not followed')
        }
      })
    }
  }
}
</script>

<style lang="stylus">
@require '../../assets/style/1-settings/colors'

.main
  margin-bottom 2rem

  &__list
    height 330px
    position relative
    background-color rgba(100, 100, 100, 0.1)
    margin-bottom 2rem

  &__side
    height 100%
    display flex
    flex-direction column
    justify-content space-between

  &__customize
    margin-bottom 3rem

  &__actions
    margin-bottom 0

    .c-button:first-child
      margin-bottom 1rem

  &__fallback
    width 100%
    height 100%
    display flex
    justify-content center
    align-items center

    > a
      border-bottom 1px dotted currentColor

  +from-width('l')
    height 330px

    .c-button
      min-width none !important
      max-width 270px
      width 100%

    &__list
      lost-column 14/24

    &__side
      lost-column 9/24
      lost-move 1/24
</style>
