import queryStringParser from 'query-string-parser'
import axios from 'axios'

const spotifyAPIUrl = 'https://api.spotify.com/v1'
const authUrl = '/api/auth'
const overrideToken =
  'BQBSLjuTPgN0DZ4aPV-MvyaOvGJc73i-vLKBCKW_5O9naK2YDU2nFlm0wgZOQmgk7IUn5BifH6HO0_fJwtCFsSKQLQm28L11bz3PrmSxWHp-htv47n1EMvfdYN9Jyvy67i_hPWl-PT2T5VzOuokjMUx-g9ycsW5Ltg-8G--6dLQXk4SWSWbgT4mv'

class SpotifyService {
  constructor() {
    if (location.hostname === 'localhost') {
      this.accessToken = overrideToken
      this.accessTokenExpiration = new Date('2019-11-03 19:00:00')
    } else {
      if (!this.isLoggedIn()) {
        this.accessToken = null
        this.accessTokenExpiration = null
      }
    }

    window.processCallback = this.processCallback.bind(this)
  }

  getAccessToken() {
    return this.accessToken
  }

  isLoggedIn() {
    console.log(
      'token',
      this.accessToken,
      'expires at',
      this.accessTokenExpiration,
      'now',
      Date.now()
    )

    if (
      !this.accessToken ||
      !this.accessTokenExpiration ||
      this.accessTokenExpiration <= Date.now()
    ) {
      console.log('not logged in')
      return false
    }

    console.log('logged in')
    return true
  }

  attemptLogin() {
    if (this.isLoggedIn()) {
      return Promise.resolve(this.accessToken)
    }
    this.popupWindow = window.open(authUrl, 'Spotify', 'width=600,height=500')
    return new Promise((resolve, reject) => {
      this.resolvePopup = resolve
      this.rejectPopup = reject
    })
  }

  processCallback(rawHash) {
    this.popupWindow.close()

    if (rawHash) {
      const hash = rawHash.substr(1)
      const response = queryStringParser.fromQuery(hash)
      const expiresAt = new Date(
        Date.now() + (response.expires_in - 300) * 1000
      )
      console.log(response, expiresAt)
      this.accessToken = response.access_token
      this.accessTokenExpiration = expiresAt.getTime()
      this.resolvePopup(this.accessToken)
    } else {
      this.rejectPopup(false)
    }
  }

  followPlaylist(id) {
    const uri = `${spotifyAPIUrl}/playlists/${id}/followers`
    return axios({
      method: 'PUT',
      url: uri,
      responseType: 'text',
      headers: {
        Authorization: `Bearer ${this.accessToken}`
      }
    })
      .then(response => {
        if (response.status != 200) {
          return false
        }
        return true
      })
      .catch(error => {
        console.log('error while following playlist', error)
        return false
      })
  }
}

export default new SpotifyService()
