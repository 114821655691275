export default [
  {
    name: 'Akustisch',
    id: 'acoustic'
  },
  {
    name: 'Afrobeat',
    id: 'afrobeat'
  },
  {
    name: 'Alternative Rock',
    id: 'alt-rock'
  },
  {
    name: 'Alternative',
    id: 'alternative'
  },
  {
    name: 'Ambient',
    id: 'ambient'
  },
  {
    name: 'Black Metal',
    id: 'black-metal'
  },
  {
    name: 'Blues',
    id: 'blues'
  },
  {
    name: 'Britisch',
    id: 'british'
  },
  {
    name: 'Klassik',
    id: 'classical'
  },
  {
    name: 'Country',
    id: 'country'
  },
  {
    name: 'Dance',
    id: 'dance'
  },
  {
    name: 'Dancehall',
    id: 'dancehall'
  },
  {
    name: 'Deep House',
    id: 'deep-house'
  },
  {
    name: 'Drum & Base',
    id: 'drum-and-bass'
  },
  {
    name: 'Dubstep',
    id: 'dubstep'
  },
  {
    name: 'EDM',
    id: 'edm'
  },
  {
    name: 'Elektro',
    id: 'electro'
  },
  {
    name: 'Emo',
    id: 'emo'
  },
  {
    name: 'Folk',
    id: 'folk'
  },
  {
    name: 'Funk',
    id: 'funk'
  },
  {
    name: 'Garage',
    id: 'garage'
  },
  {
    name: 'Deutsch',
    id: 'german'
  },
  {
    name: 'Goth',
    id: 'goth'
  },
  {
    name: 'Grunge',
    id: 'grunge'
  },
  {
    name: 'Hard Rock',
    id: 'hard-rock'
  },
  {
    name: 'Hardcore',
    id: 'hardcore'
  },
  {
    name: 'Heavy Metal',
    id: 'heavy-metal'
  },
  {
    name: 'Hip Hop',
    id: 'hip-hop'
  },
  {
    name: 'House',
    id: 'house'
  },
  {
    name: 'Indie',
    id: 'indie'
  },
  {
    name: 'Indie Pop',
    id: 'indie-pop'
  },
  {
    name: 'Industrial',
    id: 'industrial'
  },
  {
    name: 'Jazz',
    id: 'jazz'
  },
  {
    name: 'K-Pop',
    id: 'k-pop'
  },
  {
    name: 'Metal',
    id: 'metal'
  },
  {
    name: 'Metalcore',
    id: 'metalcore'
  },
  {
    name: 'Minimal Techno',
    id: 'minimal-techno'
  },
  {
    name: 'Pop',
    id: 'pop'
  },
  {
    name: 'Power Pop',
    id: 'power-pop'
  },
  {
    name: 'Progressive House',
    id: 'progressive-house'
  },
  {
    name: 'Psychedelic Rock',
    id: 'psych-rock'
  },
  {
    name: 'Punk',
    id: 'punk'
  },
  {
    name: 'Punk Rock',
    id: 'punk-rock'
  },
  {
    name: 'R&B',
    id: 'r-n-b'
  },
  {
    name: 'Reggae',
    id: 'reggae'
  },
  {
    name: 'Rock',
    id: 'rock'
  },
  {
    name: 'Rock & Roll',
    id: 'rock-n-roll'
  },
  {
    name: 'Rockabilly',
    id: 'rockabilly'
  },
  {
    name: 'Singer/Songwriter',
    id: 'singer-songwriter'
  },
  {
    name: 'Ska',
    id: 'ska'
  },
  {
    name: 'Soul',
    id: 'soul'
  },
  {
    name: 'Synthpop',
    id: 'synth-pop'
  },
  {
    name: 'Techno',
    id: 'techno'
  },
  {
    name: 'Trance',
    id: 'trance'
  },
  {
    name: 'Trip Hop',
    id: 'trip-hop'
  },
  {
    name: 'Weltmusik',
    id: 'world-music'
  }
]
