<template>
  <div class="step-result  o-panel">
    <div class="o-panel__inner">
      <div class="u-vspace-s">
        <div
          class="step-result__photo  u-vspace-xs"
          :style="{ 'background-image': 'url(' + photoData + ')' }"
        ></div>
        <h1 class="u-h1">{{ analysis.feedback.headline }}</h1>
        <p class="u-p u-loose step-result__copy">
          {{ analysis.feedback.copy }}
        </p>
      </div>
      <div class="o-row">
        <button class="c-button c-button--pez" @click="$emit('goto-step', 0)">
          Nochmal messen!
        </button>
        <button class="c-button" @click="$emit('goto-next-step')">
          Playlist erstellen!
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState(['analysis', 'photoData'])
  }
}
</script>

<style lang="stylus">
@require '../../assets/style/1-settings/colors'

.step-result
  &__photo
    display inline-block
    width 180px
    height 180px
    background-size cover
    background-position 50% 50%
    border-radius 100%
    border 2px solid $colorLightTeal
</style>
