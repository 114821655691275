<template>
  <div class="progressor" @click="start">
    <svg class="progress-ring" height="80" width="80">
      <circle
        class="progress-ring__back"
        :r="radius"
        stroke-width="2"
        fill="transparent"
        cx="40"
        cy="40"
      />
      <circle
        class="progress-ring__front"
        :r="radius"
        :stroke-dashoffset="dashOffset"
        :stroke-dasharray="dashArray"
        stroke-linecap="round"
        stroke-width="4"
        fill="transparent"
        cx="40"
        cy="40"
      />
    </svg>
    <span class="progress-ring__text" v-text="progressText"> </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      progress: 0,
      radius: 38
    }
  },
  computed: {
    progressText() {
      return Math.round(this.progress * 100) + '%'
    },
    circumference() {
      return this.radius * 2 * Math.PI
    },
    dashOffset() {
      return this.circumference - this.progress * this.circumference
    },
    dashArray() {
      return `${this.circumference} ${this.circumference}`
    }
  },
  methods: {
    mounted() {
      this.start()
    },
    reset() {
      this.progress = 0.01
    },
    start() {
      this.progress = 0.01
      setTimeout(this.tick, 100)
    },
    finish() {
      this.isFinishing = true
    },
    tick() {
      const distance = this.isFinishing ? 0.04 : (1 - this.progress) * 0.04
      this.progress += distance
      this.progress = Math.min(1, this.progress)
      if (this.progress < 1) {
        setTimeout(this.tick, 100)
      } else {
        setTimeout(() => {
          this.$emit('complete')
        }, 1000)
      }
    }
  }
}
</script>

<style lang="stylus">
@require '../assets/style/1-settings/colors'
@require '../assets/style/1-settings/fonts'

.progressor
  position relative
  width 80px
  height 80px
  margin 0 auto

.progress-ring
  margin 0 auto
  position absolute
  top 0
  left 0

  &__back
    stroke #666

  &__front
    transform-origin 50% 50%
    transform rotate(-90deg)
    transition stroke-dashoffset 0.2s
    stroke $colorBrand

  &__text
    color white
    position absolute
    white-space nowrap
    top 50%
    left 50%
    transform translate(-50%, -50%)
    font-size 20px
    font-family $fontRegular
</style>
