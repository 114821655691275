<template>
  <div class="step-intro">
    <div v-if="!isHowToOpen" class="o-panel__inner">
      <h1 class="u-h1">Entdecke jetzt den<br />Klang deiner Gefühle</h1>
      <p class="u-p  u-vspace-s">
        Möchtest du erfahren, welche Gefühle dir ins Gesicht geschrieben stehen?
        Probiere es aus: Live mit deiner Kamera/Webcam oder mit einem
        hochgeladenen Foto – und entdecke so mit Aspirin® und Spotify deine
        individuelle Playlist, die auf Basis deiner Emotionen erstellt wird.
        <br /><br />
        Deine Aufnahme wird nach der Emotionserkennung gelöscht.
      </p>
      <div class="o-row  u-vspace-s">
        <template v-if="hasCameraSupport">
          <button class="c-button c-button--pez" @click="onCamClick">
            Jetzt Emotionserkennung starten
          </button>
          <div class="file-selector  c-button">
            <label class="file-selector__label"
              >Foto hochladen
              <input
                class="file-selector__input"
                type="file"
                ref="fileInput"
                @click.stop="onFileClick"
                @change="onPhotoSelected"
              />
            </label>
          </div>
        </template>
        <template v-else>
          <div class="file-selector c-button c-button--pez">
            <label class="file-selector__label"
              >Foto hochladen
              <input
                class="file-selector__input"
                type="file"
                ref="fileInput"
                @click.stop="onFileClick"
                @change="onPhotoSelected"
              />
            </label>
          </div>
        </template>
      </div>
      <div class="c-form  u-vspace-m">
        <div
          class="c-form__checkbox"
          :class="{ 'is-invalid': $v.termsAccepted.$error }"
        >
          <input
            class="c-form__checkbox-input"
            type="checkbox"
            id="cbTerms"
            name="cbTerms"
            v-model="$v.termsAccepted.$model"
            @change="$v.termsAccepted.$touch()"
          />
          <label class="c-form__checkbox-label" for="cbTerms" style="display:inline-block;max-width:25rem;text-align:left;"
            >Ich bin damit einverstanden, dass mein Foto zur Emotionserkennung gemäß der
            <a class="u-link" href="/datenschutz" target="_blank"
              >Datenschutzerklärung</a
            >
            verarbeitet wird.</label
          >
        </div>
      </div>
      <div class="howto-btn" @click="openHowTo()">
        So funktioniert’s <InfoIcon class="howto-btn__icon" />
      </div>
    </div>
    <div v-else class="o-panel__inner  howto">
      <h1 class="u-h1 u-vspace-s">So funktioniert die Emotionserkennung</h1>
      <h2 class="u-h3">Via Webcam</h2>
      <p class="u-p  u-vspace-s">
        Achte darauf, dass es hell genug ist und dein Gesicht im Kreis bleibt.
      </p>
      <h2 class="u-h3">Via Foto-Upload</h2>
      <p class="u-p  u-vspace-l">
        Achte darauf, dass das Gesicht der Person auf dem Foto gut zu erkennen
        ist.
      </p>
      <div class="howto__close" @click="closeHowTo()">
        <CloseIcon />
      </div>
    </div>
  </div>
</template>

<script>
import loadImage from 'blueimp-load-image'
import { validationMixin } from 'vuelidate'
import { sameAs } from 'vuelidate/lib/validators'
import InfoIcon from '@/assets/images/icon-info.svg?inline'
import CloseIcon from '@/assets/images/icon-close.svg?inline'

export default {
  mixins: [validationMixin],
  components: {
    InfoIcon,
    CloseIcon
  },
  data() {
    return {
      termsAccepted: false,
      isHowToOpen: false,
      hasCameraSupport: false
    }
  },
  validations: {
    termsAccepted: {
      sameAs: sameAs(() => true)
    }
  },
  methods: {
    isValid() {
      this.$v.$touch()
      return !this.$v.$invalid
    },
    onCamClick() {
      if (!this.isValid()) return
      this.$store.commit('setUsePhotoFallback', false)
      this.$store.commit('clearPhoto')
      this.$emit('goto-next-step')

      if (window.fbq) {
        window.fbq('trackCustom', 'FacetestStart')
      }
    },
    onFileClick(event) {
      if (!this.isValid()) {
        event.preventDefault()
      }

      if (window.fbq) {
        window.fbq('trackCustom', 'FacetestUpload')
      }
    },
    onPhotoSelected() {
      let photoFile = this.$refs.fileInput.files[0]
      loadImage(
        photoFile,
        canvas => {
          this.$store.commit('setPhotoData', canvas.toDataURL())
          this.$store.commit('setUsePhotoFallback', true)
          this.$emit('goto-next-step')
        },
        {
          canvas: true,
          orientation: true
        }
      )
    },
    openHowTo() {
      this.isHowToOpen = true
    },
    closeHowTo() {
      this.isHowToOpen = false
    }
  },
  created() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices.enumerateDevices().then(devices => {
        this.hasCameraSupport = devices.some(item => item.kind === 'videoinput')
      })
    } else {
      this.hasCameraSupport = false
    }
  }
}
</script>

<style lang="stylus">
.file-selector
  cursor pointer

  &__input
    display none

.howto
  display flex
  flex-direction column
  align-items center
  height 100%

  .u-h3
    margin-bottom 0

  &__close
    width 25px
    margin-top 2rem

.howto-btn
  align-items center
  margin 0 auto

  &__icon
    margin-left 5px
    width 26px
    height 26px
</style>
