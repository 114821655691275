<template>
  <div class="step-sweepstake  o-panel">
    <template v-if="state == 'idle' || state == 'loading'">
      <div class="o-panel__inner o-panel__inner--narrow  u-vspace-xs">
        <h1 class="u-h1">
          Es wird noch besser: <br />
          Jetzt Gutschein für Spotify Premium gewinnen
        </h1>
        <p class="u-p">
          Wir hoffen, dir gefällt deine persönliche Playlist. Und für ein noch
          besseres Hörerlebnis ohne Einschränkungen gibt’s hier die Chance auf
          einen 10€ Gutschein für Spotify. Einfach mitmachen!
        </p>
      </div>
      <form
        :class="[
          'c-form',
          'o-panel__inner',
          'o-panel__inner--narrow',
          { 'is-disabled': state == 'loading' },
          { 'is-legacy': isLegacyBrowser }
        ]"
        @submit.prevent="submit"
      >
        <div class="u-vspace-xs">
          <div class="c-form__row">
            <span
              :class="[
                'c-form__field',
                'c-form__field--validator',
                {
                  'c-form__field--invalid': $v.entry.firstName.$error,
                  'c-form__field--valid':
                    !$v.entry.firstName.$error && $v.entry.firstName.$dirty
                }
              ]"
            >
              <float-label>
                <input
                  class="c-form__input"
                  type="text"
                  :disabled="state == 'loading'"
                  placeholder="Vorname"
                  v-model.trim="entry.firstName"
                />
              </float-label>
            </span>
            <span
              :class="[
                'c-form__field',
                'c-form__field--validator',
                {
                  'c-form__field--invalid': $v.entry.lastName.$error,
                  'c-form__field--valid':
                    !$v.entry.lastName.$error && $v.entry.lastName.$dirty
                }
              ]"
            >
              <float-label>
                <input
                  class="c-form__input"
                  type="text"
                  :disabled="state == 'loading'"
                  placeholder="Nachname"
                  v-model.trim="entry.lastName"
                />
              </float-label>
            </span>
          </div>
          <div class="c-form__row">
            <span
              :class="[
                'c-form__field',
                'c-form__field--validator',
                {
                  'c-form__field--invalid': $v.entry.email.$error,
                  'c-form__field--valid':
                    !$v.entry.email.$error && $v.entry.email.$dirty
                }
              ]"
            >
              <float-label>
                <input
                  class="c-form__input"
                  type="text"
                  :disabled="state == 'loading'"
                  placeholder="E-Mail"
                  v-model.trim="entry.email"
                />
              </float-label>
            </span>
            <span
              :class="[
                'c-form__field',
                {
                  'c-form__field--invalid': $v.entry.recaptchaResponse.$error,
                  'c-form__field--valid':
                    !$v.entry.recaptchaResponse.$error &&
                    $v.entry.recaptchaResponse.$dirty
                }
              ]"
            >
              <div class="c-form__captcha">
                <vue-recaptcha
                  :sitekey="recaptchaSiteKey"
                  :loadRecaptchaScript="true"
                  @verify="onRecaptchaVerified"
                  @expired="onRecaptchaExpired"
                ></vue-recaptcha>
              </div>
            </span>
          </div>
        </div>
        <div class="u-vspace-s">
          <div
            class="c-form__checkbox"
            :class="{ 'is-invalid': $v.entry.termsAccepted.$error }"
          >
            <input
              class="c-form__checkbox-input"
              type="checkbox"
              :disabled="state == 'loading'"
              id="cbTerms"
              name="cbTerms"
              v-model="$v.entry.termsAccepted.$model"
              @change="$v.entry.termsAccepted.$touch()"
            />
            <label class="c-form__checkbox-label" for="cbTerms"
              >Ich habe die
              <a class="u-link" href="/teilnahmebedingungen" target="_blank"
                >Teilnahmebedingungen</a
              >
              gelesen und akzeptiert.</label
            >
          </div>
        </div>
        <div class="o-row">
          <button
            type="submit"
            :disabled="state == 'loading'"
            class="c-button c-button--pill"
          >
            Jetzt teilnehmen
          </button>
        </div>
      </form>
    </template>

    <template v-if="state == 'success'">
      <div class="step-sweepstake__result  o-panel__inner">
        <div class="big-icon big-icon__valid  u-vspace-s"><ValidIcon /></div>
        <h1 class="u-h0">Danke!</h1>
        <p class="u-h3" style="max-width:33rem;margin:0 auto;">
          Deine Teilnahme am Gewinnspiel wurde versendet. Im Falle eines
          Gewinnes wirst du durch uns benachrichtigt.
        </p>
      </div>
    </template>
    <template v-if="state == 'error'">
      <div class="step-sweepstake__result  o-panel__inner">
        <div class="big-icon  u-vspace-s"><img :src="invalidIcon" /></div>
        <h1 class="u-h0">Hoppla!</h1>
        <p class="u-h3">
          Leider ist beim Senden ein Fehler aufgetreten. Bitte versuche es
          nochmal.
        </p>
        <button class="c-button c-button--pill" @click="state = 'idle'">
          Zurück
        </button>
      </div>
    </template>
    <template v-if="state == 'duplicate'">
      <div class="step-sweepstake__result  o-panel__inner">
        <div class="big-icon  u-vspace-s"><img :src="invalidIcon" /></div>
        <h1 class="u-h0">Hoppla!</h1>
        <p class="u-h3">
          Du hast mit dieser E-Mail-Adresse bereits am Gewinnspiel teilgenommen.
        </p>
        <button class="c-button c-button--pill" @click="state = 'idle'">
          Zurück
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import device from '@/util/device'
import { validationMixin } from 'vuelidate'
import { sameAs, required, email } from 'vuelidate/lib/validators'
import VueRecaptcha from 'vue-recaptcha'
import FloatLabel from 'vue-float-label/components/FloatLabel'
import ValidIcon from '@/assets/images/icon-checkmark.svg?inline'
import InvalidIcon from '@/assets/images/icon-attention-filled.svg'

export default {
  mixins: [validationMixin],
  components: {
    VueRecaptcha,
    FloatLabel,
    ValidIcon
  },
  validations: {
    entry: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      recaptchaResponse: { required },
      termsAccepted: {
        sameAs: sameAs(() => true)
      }
    }
  },
  computed: {
    invalidIcon() {
      return InvalidIcon
    },
    recaptchaSiteKey() {
      return process.env.VUE_APP_RECAPTCHA_SITE_KEY
    },
    isLegacyBrowser() {
      return device.isIE()
    }
  },
  data() {
    return {
      state: 'idle',
      entry: {
        firstName: '',
        lastName: '',
        email: '',
        recaptchaResponse: '',
        termsAccepted: false
      }
    }
  },
  methods: {
    onRecaptchaVerified(response) {
      this.entry.recaptchaResponse = response
      this.$v.entry.recaptchaResponse.$touch()
    },
    onRecaptchaExpired() {
      this.entry.recaptchaResponse = ''
      this.$v.entry.recaptchaResponse.$touch()
    },
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.state = 'loading'
      axios
        .post('/api/sweepstake', this.entry)
        .then(response => {
          if (
            response.status < 500 &&
            response.data &&
            response.data.status == 'ok'
          ) {
            this.state = 'success'
            this.$store.commit('setSweepstakeSubmitted', true)
          } else {
            throw new Error('invalid response!')
          }
        })
        .catch(error => {
          if (error.response && error.response.status == 409) {
            this.state = 'duplicate'
            this.entry.email = ''
          } else {
            this.state = 'error'
          }
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.big-icon
  width 100%
  text-align center

  > svg,
  > img
    height 100px
    width auto

  &__valid
    > svg path
      fill #18eda5 !important
</style>
