<template>
  <section class="symphony  o-panel  u-center">
    <div class="symphony__bg" data-bg="1"></div>
    <div class="symphony__bg" data-bg="2"></div>
    <div class="symphony__bg" data-bg="3"></div>
    <div class="symphony__inner">
      <template v-if="usageAccepted">
        <div class="symphony__step">
          <div class="symphony__step-progress  u-vspace-s">
            <span class="u-p">Schritt {{ stepDisplay }}</span>
          </div>
          <transition
            :name="isGoingForward ? 'slide' : 'slide-reverse'"
            mode="out-in"
          >
            <keep-alive>
              <component
                class="symphony__step-module"
                :is="currentModule"
                @goto-step="gotoStep"
                @goto-next-step="gotoNextStep"
                @goto-previous-step="gotoPreviousStep"
              />
            </keep-alive>
          </transition>
        </div>
      </template>
      <template v-else>
        <div class="symphony__step">
          <div>
            <h2 class="u-h1">Hinweis</h2>
            <p class="u-p" style="max-width:36rem;margin:0 auto;">Zur Nutzung der Emotionserkennung ist deine Zustimmung zur Einbindung der Dienste von Spotify und Google reCAPTCHA zwingend erforderlich.</p>
            <p>Ändere deine Einstellungen auf der <router-link to="/datenschutz#cookies" class="u-link" target="_blank">Datenschutzseite</router-link>.</p>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import StepIntro from './symphony/StepIntro'
import StepFace from './symphony/StepFace'
import StepResult from './symphony/StepResult'
import StepPlaylist from './symphony/StepPlaylist'
import StepSweepstake from './symphony/StepSweepstake'

const stepModules = [
  StepIntro,
  StepFace,
  StepResult,
  StepPlaylist,
  StepSweepstake
]

export default {
  data() {
    return {
      currentStep: 0,
      isGoingForward: true,
      usageAccepted: true
    }
  },
  computed: {
    currentModule() {
      return stepModules[this.currentStep]
    },
    stepDisplay() {
      switch (this.currentStep) {
        case 0:
          return '1/3'
        case 1:
          return '2/3'
        case 2:
          return '2/3'
        case 3:
          return '2/3'
        case 4:
          return '3/3'
      }
    }
  },
  methods: {
    gotoPreviousStep() {
      this.gotoStep(this.currentStep - 1)
    },
    gotoNextStep() {
      this.gotoStep(this.currentStep + 1)
    },
    gotoStep(step) {
      let targetStep = Math.min(stepModules.length - 1, Math.max(0, step))
      this.isGoingForward = targetStep > this.currentStep
      this.currentStep = targetStep
    },
    checkAcceptance() {
      if (window.localStorage.getItem('cookieconsent')) {
        const settings = JSON.parse(
          window.localStorage.getItem('cookieconsent')
        )
        this.usageAccepted =
          settings.spotify === true && settings.recaptcha === true
      } else {
        this.usageAccepted = false
      }

      setTimeout(this.checkAcceptance, 1000)
    }
  },
  mounted() {
    // setTimeout(this.checkAcceptance, 1000)
  }
}
</script>

<style lang="stylus">
@require '../assets/style/1-settings/layout'

.symphony
  position relative
  background-color #131c20
  color white

  &:before
    content ''
    display block
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    background-image linear-gradient(#002d30 0%, #003335 40%, #006a5b 100%)
    opacity 0.45

  &__bg
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    background-repeat no-repeat
    pointer-events none

    background-image url('../assets/images/bg/bg-symphony.jpg')
    background-size cover

  &__inner
    position relative

  &__step
    display flex
    min-height 100vh
    min-height calc(var(--vh, 1vh) * 100)
    padding-top 5rem
    padding-bottom 2rem
    flex-direction column
    justify-content space-around

    +from-width('m')
      padding-top 7%
      padding-bottom 3%

  &__step-module
    min-height 60vmin
    display flex
    flex-direction column
    justify-content center
    align-items center
    margin auto 0

    +from-width('l')
      lost-column 8/12
      lost-move 2/12

    .u-p
      max-width 550px
      margin-left auto
      margin-right auto
</style>
