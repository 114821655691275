<template>
  <div class="step-face">
    <div
      :class="[
        'face-viewport',
        { 'face-viewport--fallback': usePhotoFallback }
      ]"
    >
      <template v-if="usePhotoFallback">
        <div style="position:absolute;top:0;left:0;width:100%;height:100%">
          <img
            class="face-viewport__photo"
            :src="photoData"
            ref="viewportPhoto"
            data-object-fit="contain"
            data-object-position="center center"
          />
        </div>
      </template>
      <template v-else>
        <div style="position:absolute;top:0;left:0;width:100%;height:100%">
          <video
            class="face-viewport__video"
            playsinline
            ref="viewportVideo"
          ></video>
          <div class="c-spinner" v-if="!isCameraRunning"></div>
        </div>
      </template>
      <div
        v-show="isCameraRunning && !usePhotoFallback"
        class="face-viewport__decoration"
      ></div>
      <canvas class="face-viewport__overlay" ref="viewportOverlay"></canvas>

      <div class="face-viewport__countdown" v-if="countdown.isRunning">
        <span>{{ countdown.secondsLeft }}</span>
      </div>
    </div>

    <div class="step-face__action">
      <div v-show="!countdown.isRunning && !isAnalysing">
        <div class="trigger-label">Jetzt Messung starten</div>
        <button class="trigger" @click="trigger">
          <CameraIcon />
        </button>
      </div>
      <div v-show="isAnalysing">
        <span class="trigger__progress">
          <Progressor ref="progressor" @complete="onAnalysisComplete" />
        </span>
        <div class="trigger-label">
          Dein Ergebnis wird analysiert &hellip;
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import eventBus from '@/util/event-bus'
import Progressor from '@/components/Progressor'
import CameraIcon from '@/assets/images/new/click-circle.svg?inline'
require('objectFitPolyfill')

const MIN_DURATION = 2000
// const CAM_SIZE = 800
// const SNAPSHOT_SIZE = 800

export default {
  components: {
    Progressor,
    CameraIcon
  },
  computed: {
    ...mapState(['photoPath', 'photoData', 'usePhotoFallback'])
  },
  data() {
    return {
      isCameraRunning: false,
      isAnalysing: false,
      snapshotBlob: null,
      countdown: {
        isRunning: false,
        secondsLeft: 3
      }
    }
  },
  created() {
    axios
      .get('/api/initialize')
      .then(result => {
        if (result.status != 200) {
          throw new Error('session initialization failed!')
        }
        console.log('session initialized!')
      })
      .catch(error => {
        console.log('session initialization network error!', error)
      })
  },
  mounted() {},
  activated() {
    this.isAnalysing = false
    this.$refs.progressor.reset()
    if (!this.usePhotoFallback) {
      this.activateCamera()
    } else {
      window.objectFitPolyfill(this.$refs.viewportPhoto)
    }
  },
  deactivated() {},
  methods: {
    activateCamera() {
      if (!this.videoStream) {
        this.initializeCamera()
        return
      }
      this.$refs.viewportVideo.srcObject = this.videoStream
      this.$refs.viewportVideo.play()
      // window.objectFitPolyfill(this.$refs.viewportVideo)
      this.isCameraRunning = true
    },
    deactivateCamera() {
      this.$refs.viewportVideo.srcObject = null
      this.isCameraRunning = false
    },
    initializeCamera() {
      navigator.mediaDevices
        .getUserMedia({
          // video: {
          // width: CAM_SIZE
          // facingMode: 'user'
          // },
          video: true,
          audio: false
        })
        .then(stream => {
          this.videoStream = stream
          this.activateCamera()
        })
        .catch(error => {
          console.log(error)
          this.$emit('goto-previous-step')
        })
    },
    trigger() {
      if (this.isAnalysing) return
      if (this.usePhotoFallback) {
        this.takeSnapshot()
        return
      }

      this.countdown.isRunning = true
      this.countdown.secondsLeft = 3
      setTimeout(this.tickCountdown.bind(this), 1000)
    },
    tickCountdown() {
      this.countdown.secondsLeft--
      if (this.countdown.secondsLeft == 0) {
        this.countdown.isRunning = false
        eventBus.$emit('fire-flash')
        setTimeout(this.takeSnapshot, 50)
      } else {
        setTimeout(this.tickCountdown.bind(this), 1000)
      }
    },
    takeSnapshot() {
      let snapshotCanvas = document.createElement('canvas')
      let snapshotSource, sourceWidth, sourceHeight

      if (this.usePhotoFallback) {
        snapshotSource = this.$refs.viewportPhoto
        sourceWidth = this.$refs.viewportPhoto.naturalWidth
        sourceHeight = this.$refs.viewportPhoto.naturalHeight
      } else {
        snapshotSource = this.$refs.viewportVideo
        sourceWidth = this.$refs.viewportVideo.videoWidth
        sourceHeight = this.$refs.viewportVideo.videoHeight
        this.$refs.viewportVideo.pause()
      }

      snapshotCanvas.setAttribute('width', sourceWidth)
      snapshotCanvas.setAttribute('height', sourceHeight)
      let context = snapshotCanvas.getContext('2d')
      context.drawImage(snapshotSource, 0, 0)

      const snapshotData = snapshotCanvas.toDataURL('image/jpeg', 0.8)
      this.$store.commit('setPhotoData', snapshotData)

      const binary = atob(snapshotData.split(',')[1])
      const ab = new ArrayBuffer(binary.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < binary.length; i++) {
        ia[i] = binary.charCodeAt(i)
      }
      const blob = new Blob([ab], { type: 'image/jpeg' })
      this.processSnapshot(blob)
    },
    processSnapshot(blob) {
      if (!blob) {
        this.$store.commit('setAnalysis', null)
        this.$emit('goto-previous-step')
        return
      }

      this.isAnalysing = true
      this.$nextTick(() => this.$refs.progressor.start())

      let formData = new FormData()
      formData.append('file', blob, 'tmp.jpg')
      let stallPromise = new Promise(resolve => {
        setTimeout(resolve, MIN_DURATION)
      })
      let uploadPromise = axios.post('/api/photo', formData, {
        headers: {
          'Content-type': `multipart/form-data; boundary=${formData._boundary}`
        }
      })

      Promise.all([uploadPromise, stallPromise])
        .then(results => {
          let uploadResult = results[0]
          if (!uploadResult.data.analysis) {
            console.log('no face!')
            this.$store.commit('setAnalysis', null)
            this.$emit('goto-previous-step')
          } else {
            console.log('ok!', uploadResult.data)
            this.$refs.progressor.finish()
            this.$store.commit('setAnalysis', uploadResult.data.analysis)
          }
        })
        .catch(error => {
          console.log('error!', error)
          this.$store.commit('setAnalysis', null)
          this.$emit('goto-previous-step')
        })
    },
    onAnalysisComplete() {
      this.$emit('goto-next-step')
    }
  }
}
</script>

<style lang="stylus">
@require '../../assets/style/1-settings/colors'
@require '../../assets/style/1-settings/fonts'

.step-face
  display flex
  flex-direction column
  justify-content space-between
  align-items center

  &__action
    position relative
    min-height 8rem

.face-viewport
  position relative
  width 100%
  margin-bottom 1rem
  overflow hidden
  background-color rgba(0, 0, 0, 0.1)
  max-width 380px

  @media (max-height: 800px)
    max-width 294px

  &:before
    display block
    content ''
    width 100%
    height 0
    padding-bottom percentage(350/350)

    +from-width('m')
      padding-bottom percentage(468/380)

  &__decoration
    position absolute
    top 0
    left 0
    bottom 0
    right 0
    width 100%
    height 100%

    opacity 0.15
    background-size 100% 50%
    background linear-gradient(to bottom,
      transparent 0%, transparent 33%, #fff 33%, #fff 33.2%,
      transparent 33.2%, transparent 66%, #fff 66%, #fff 66.2%,
      transparent 66.2%, transparent 100%),
    linear-gradient(to right,
      transparent 0%, transparent 33%, #fff 33%, #fff 33.2%,
      transparent 33.2%, transparent 66%, #fff 66%, #fff 66.2%,
      transparent 66.2%, transparent 100%)

    &:after
      position absolute
      content ''
      width 3rem
      height 3rem
      border-radius 100%
      border solid white 6px
      left 50%
      top 50%
      transform translate(-50%, -50%)

  &__video,
  &__overlay
    position absolute
    top 0
    left 50%
    height 100%
    transform translate(-50%, 0) scaleX(-1)

  &__photo
    display block
    object-fit contain

  &__countdown
    position absolute
    width 100%
    text-align center
    top 41%
    left 0

    > span
      font-family $fontBold
      font-size 90px
      line-height 96px
      width 120px
      height 120px

.trigger-label
  margin 1.5rem 0

.trigger
  background none
  border none
  cursor pointer

  svg
    width 4rem
    height 4rem
</style>
